import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import styled from 'styled-components'

import { LargeP, primary } from '../elements'

const EquipmentPage = ({ data }) => {
  const postNode = {
    title: `Equipment - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Top of the Line Equipment - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="equipment" customTitle />

      <ServiceContainer>
        <PageTitle>Our Equipment</PageTitle>
        <LargeP>
          We run and maintain the best modern equipment in the industry with
          safety and reliability top-of-mind.
        </LargeP>
        <p>
          Our tractor and trailer fleet has an average age of 2 years and we
          continually cycle older equipment, replacing it with the latest
          models. Our fleet is California Air Resources Board (CARB) verified
          for Certified Clean Idle. As a registered EPA SmartWay™ Transportation
          Partner, we reduce fuel use with increased efficiency. We also provide
          drivers’ lane departure, stability control, and hand-braking so our
          drivers can be as safe as possible while on the road.
        </p>
        <h4>Tractors</h4>
        <EquipmentList>
          <li>Modern Freightliner and Peterbilt Tractors 2017 and newer</li>
          <li>
            All tractors are 100% California Air Resources Board (CARB)
            compliant
          </li>
          <li>
            All tractors are equipped with Qual Comm for messaging, satellite
            tracking so you always know where the tractor is, and electronic
            logs
          </li>
          <li>
            All tractors have in cab scanning capability to get the documents
            you need when you need them
          </li>
          <li>
            All tractors are equipped with wide base tires to reduce weight and
            increase fuel efficiency
          </li>
          <li>
            All tractors are equipped with APU's to conserve fuel efficiency,
            comply with idle laws, and provide the drivers comfort
          </li>
        </EquipmentList>
        <h4>Trailers</h4>
        <EquipmentList>
          <li>All trailers are 53 Ft 102 wide air ride</li>
          <li>
            All trailers are 100% California Air Resources Board (CARB)
            compliant
          </li>
          <li>
            All trailers are equipped with state of the art temperature
            controllers, the most advanced Microprocessor controls in the
            industry
          </li>
          <li>
            Trip recorders on all units, for the maximum protection of cargo
          </li>
          <li>
            All units feature the largest airflow capacity system in the
            industry (3350-CFM)
          </li>
          <li>All units use "Ozone Friendly" refrigerant</li>
          <li>All trailers are equipped with trailer tracking</li>
          <li>
            All trailers are equipped with wide base tires to reduce weight and
            increase fuel efficiency
          </li>
        </EquipmentList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default EquipmentPage

const EquipmentList = styled.ul`
  margin-bottom: 2.5rem;
  li {
    text-align: left;
    list-style: none;
    padding-bottom: 0.85rem;
    line-height: 1.35;
    &:before {
      font-family: 'Material Icons';
      content: 'keyboard_arrow_right';
      color: ${primary};
      padding-right: 0.5rem;
      margin-left: -1.5rem;
    }
  }
`
